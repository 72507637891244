import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '../../components/helper';

 
export const OfferList = createAsyncThunk(
  'offers/list',
  async () => {
    const response = await http.get('offerlist');
    return response.data;
  }
);

const initialState = {
  offerlist: [],
  status: 'idle',
  error: null,
};


const offerSlice = createSlice({
  name: 'Offers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(OfferList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(OfferList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.offerlist = action.payload;
      })
      .addCase(OfferList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

 
export default offerSlice.reducer;