import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../components/helper";

export const payConfirm = createAsyncThunk(
  "payments/payConfirm",
  async (payload) => {
    const response = await http.post("pay", payload);
    return response.data;
  }
);

const initialState = {
  paymentInfo: [],
  paymentStatus: "idle",
  error: null,
};

const paymentSlice = createSlice({
  name: "Payments",
  initialState,
  reducers: {
    resetPaymentInfo(state) {
      state.paymentInfo = [];
      state.paymentStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(payConfirm.pending, (state) => {
        state.paymentStatus = "loading";
      })
      .addCase(payConfirm.fulfilled, (state, action) => {
        state.paymentStatus = "succeeded";
        state.paymentInfo = action.payload;
      })
      .addCase(payConfirm.rejected, (state, action) => {
        state.paymentStatus = "failed";
        state.error = action.error.message;
      });
  },
});
export const { resetPaymentInfo } = paymentSlice.actions;
export default paymentSlice.reducer;
