import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../components/helper";

export const placeOrder = createAsyncThunk(
  "order/placeOrder",
  async (payload) => {
    const response = await http.post("order", payload);
    return response.data;
  }
);
export const fetchOrders = createAsyncThunk(
  "order/fetchOrders",
  async (payload) => {
    const response = await http.post("orders", payload);
    return response.data;
  }
);

export const searchOrders = createAsyncThunk(
  "order/searchOrders",
  async ({ accountCode, searchField, searchText }) => {
    const response = await http.get(`searchorders/${accountCode}/${searchField}/${encodeURIComponent(searchText)}`);
    return response.data;
  }
);

export const updateOrders = createAsyncThunk(
  "order/updateOrders",
  async ({ accountCode, orderNumber }) => {
    const response = await http.get(`updateorderitems/${accountCode}/${orderNumber}`);
    return response.data;
  }
);

export const getOrders = createAsyncThunk(
  "order/getOrders",
  async ({ orderNumber }) => {
    const response = await http.get(`order/${orderNumber}`);
    return response.data;
  }
);

export const getUnPaidOrders = createAsyncThunk(
  'order/getUnPaidOrders',
  async (payload) => {
    const response = await http.post('unpaidorders', payload);
    return response.data.data;
  }
);


const initialState = {
  orderInfo: [],
  orderList: [],
  searchOrderList: [],
  viewOrder: [],
  getOrderInfo: [],
  placeOrderStatus: "idle",
  fetchOrderStatus: "idle",
  getOrderStatus: "idle",
  searchOrderStatus: "idle",
  updateOrderStatus: "idel",
  error: null,
  unPaidOrders: [],
  unPaidOrderStatus: 'idle',
  unPaidError: null,

};

const orderSlice = createSlice({
  name: "Orders",
  initialState,
  reducers: {
    resetOrderInfo(state) {
      state.orderInfo = [];
      state.placeOrderStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(placeOrder.pending, (state) => {
        state.placeOrderStatus = "loading";
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        state.placeOrderStatus = "succeeded";
        state.orderInfo = action.payload;
      })
      .addCase(placeOrder.rejected, (state, action) => {
        state.placeOrderStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchOrders.pending, (state) => {
        state.fetchOrderStatus = "loading";
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.fetchOrderStatus = "succeeded";
        state.orderList = action.payload;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.fetchOrderStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(searchOrders.pending, (state) => {
        state.searchOrderStatus = "loading";
      })
      .addCase(searchOrders.fulfilled, (state, action) => {
        state.searchOrderStatus = "succeeded";
        state.searchOrderList = action.payload;
      })
      .addCase(searchOrders.rejected, (state, action) => {
        state.searchOrderStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(updateOrders.pending, (state) => {
        state.updateOrderStatus = "loading";
      })
      .addCase(updateOrders.fulfilled, (state, action) => {
        state.updateOrderStatus = "succeeded";
        state.viewOrder = action.payload;
      })
      .addCase(updateOrders.rejected, (state, action) => {
        state.updateOrderStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(getOrders.pending, (state) => {
        state.getOrderStatus = "loading";
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.getOrderStatus = "succeeded";
        state.getOrderInfo = action.payload;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.getOrderStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(getUnPaidOrders.pending, (state) => {
        state.unPaidOrderStatus = 'loading';
      })
      .addCase(getUnPaidOrders.fulfilled, (state, action) => {
        state.unPaidOrderStatus = 'succeeded';
        state.unPaidOrders = action.payload;
      })
      .addCase(getUnPaidOrders.rejected, (state, action) => {
        state.unPaidOrderStatus = 'failed';
        state.unPaidError = action.error.message;
      });
  },
});

export const { resetOrderInfo } = orderSlice.actions;
export default orderSlice.reducer;
