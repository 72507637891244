import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../components/helper";

export const getingredients = createAsyncThunk(
  "ingredient/getingredients",
  async () => {
    const response = await http.get("ingredientlist");
    return response.data.data;
  }
);

const initialState = {
  ingredientList: [],
  status: "idle",
  error: null,
};

const ingredientSlice = createSlice({
  name: "Ingredient",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getingredients.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getingredients.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.ingredientList = action.payload;
      })
      .addCase(getingredients.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default ingredientSlice.reducer;
