import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '../../components/helper';
import { getStorage } from '../../components/utilityfunctions';

export const fetchSettings = createAsyncThunk(
  'settings/fetchSettings',
  async () => {
    let endPoint = "settingswithoutauth";
    if(getStorage('isLoggedIn')){
      endPoint = "settings";
    }
    const response = await http.get(endPoint);
    return response.data;
  }
);


const initialState = {
  sitesettings: [],
  status: 'idle',
  error: null,
};


const settingsSlice = createSlice({
  name: 'Settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sitesettings = action.payload;
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      
  },
});

export default settingsSlice.reducer;