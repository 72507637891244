import React, { Component } from 'react';

class Loader extends Component {

    render() {
        
        return(
            <>

                <div className={`load-flx ${this.props.propsClassName}`}>
                    <div id="loading"></div>
                </div>

                {/* Class Name: (load-smll -- for Small loader), (load-lag -- for large loader)  */}

            </>
        );

    }

}

export default Loader;