import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '../../components/helper';

 
export const NewProductPDF = createAsyncThunk(
  'pdf/list',
  async () => {
    const response = await http.get('listpdf');
    return response.data;
  }
);

const initialState = {
  pdfData: [],
  status: 'idle',
  error: null,
};


const pdfSlice = createSlice({
  name: 'PdfData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(NewProductPDF.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(NewProductPDF.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pdfData = action.payload;
      })
      .addCase(NewProductPDF.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

 
export default pdfSlice.reducer;