import axios from 'axios';
import { Navigate, Outlet } from "react-router-dom";
import { setStorage, getStorage } from '../utilityfunctions';
import { BaseUrl } from '../common/config';

export const Authenticated = () => {
  return getStorage("isLoggedIn") ? <Outlet /> : <Navigate to="/login" />;
}


const instance = axios.create({
  baseURL: `${BaseUrl}`,
});


instance.interceptors.request.use(
  (config) => {
    let token = getStorage("token") || "";
    config.headers = {
      ...config.headers,
      "Auth-Token": token,
      'Content-Type': 'application/json',
    };
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== '/refresh') {

      originalRequest._retry = true
      let payload = {
        uuid: getStorage("uuid"),
      }
      return instance
        .post('/refresh', payload)
        .then(res => {
          console.log(res)
          if (res.status === 200) {
            setStorage("token", res.data.data.token);
            originalRequest.headers['Auth-Token'] = res.data.data.token;
            return instance(originalRequest);
          }
        })
    } else if (originalRequest.url === '/refresh') {
      window.location.href = "/logout"
    }
    return Promise.reject(error)
  }
)

export const http = instance;